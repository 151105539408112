<template>
  <div>
    <div class="filter-area">
      <div class="tag-group" v-show="checkList.length">
        <div class="tag-group-content">
            <el-tag v-for="(item,index) in checkList" :key="`${item}_${index}`" closable disable-transitions
                    @close="tagHandleClose(item)">{{ item }}
            </el-tag>
        </div>
        <div>
          <el-button class="t-clear" size="small" @click="clearAllChecked">
            {{ $i18n.t('common.clear') }}
          </el-button>
        </div>
      </div>
      <div class="filter-area-content">
        <div class="filter">
          <div class="filter-label">{{ $i18n.t('products.productTypes') }}</div>
          <div class="filter-content">
            <ul>
              <template>
                <li :class="{'active': isAll === true}" @click="getAllProduct">{{ $i18n.t('common.all') }}</li>
                <li :class="{'active': typeActiveId === item.id && isAll === false}" v-for="item in productType" :key="item.id" @click="typeActive(item)">{{ item.name }}</li>
              </template>
              <li><el-checkbox v-model="isShowEom" @change="showEom">Show EOM</el-checkbox></li>
            </ul>
          </div>
        </div>
        <el-collapse-transition>
          <div v-show="expandCase">
            <template v-for="item in ['Series', 'Form']">
              <div v-if="dataMap[item] && dataMap[item].length" class="filter" :key="item">
                <div class="filter-label">{{ item }}</div>
                <div class="filter-content">
                  <el-checkbox-group v-model="checkList">
                    <div>
                      <span class="filter-content-all" :class="{'filter-content-all_active': checkFullMap[item]}" @click="checkAll(item)">{{ $i18n.t('common.all') }}</span>
                      <el-checkbox v-for="i in dataMap[item]" :label="i.value" :key="i.id"></el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </template>
            <div v-if="dataMap['other'] && dataMap['other'].length" class="filter">
              <div class="filter-label">More</div>
              <div class="filter-content">
                <div style="display: flex; flex-wrap: wrap;">
                  <div class="tags" :class="{'tags_hover': item.type === index}" v-for="item in dataMap['other']" :key="item.type" @mouseenter="hoverEnter($event, item)" @mouseleave="hoverOver">
                    <div class="tag" >{{ item.type }}&nbsp;&nbsp;&nbsp;<span class="el-icon-caret-bottom"></span></div>
                  </div>
                  <div v-show="unhidden" class="tips-box" :style="{'top': offsetTop}" @mouseenter="tipsBoxEnter" @mouseleave="tipsBoxOver">
                    <div style="display: flex; width: 100%;">
                      <el-checkbox-group v-model="checkList">
                        <el-checkbox v-for="i in other" :label="i.value" :key="i.id"></el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
        <div class="filter-area-search">
          <div class="right-wrap">
            <div class="total-num">
              <span>Results Found</span>
              <span>{{ total }}</span>
            </div>
            <el-input :placeholder="$i18n.t('common.enter')" size="small" v-model.trim="keyWord" clearable @clear="search" @keyup.native.enter="search">
              <el-button slot="append" icon="el-icon-search" @click="search"/>
            </el-input>
          </div>
        </div>
        <div @click="expandCase=!expandCase" class="expand" v-if="productType&&productType.length">
          <span>{{ expandCase ? $i18n.t('common.collapse') : $i18n.t('common.expand') }}</span>
          <i :class="expandCase?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFilterItem, getProductTypeFilter} from '../../../../api/product';
import {throttle} from 'lodash';
export default {
  name: 'FilterArea',
  data() {
    return {
      productType: [],
      typeActiveId: 0,
      dataMap: {
        other: []
      },
      checkList: [],
      other: [],
      offsetTop: null,
      index: '',
      index2: '',
      unhidden: false,
      keyWord: '',
      expandCase: true,
      vkMap: {},
      checkFullMap: {},
      isAll: false,
      isShowEom: false
    }
  },
  props: {
    total: Number
  },
  mounted() {
    this.init()
  },
  watch: {
    checkList(val) {
      this.checkFull(val)
      this.filterChange()
    }
  },
  methods: {
    showEom() {
      this.$emit('showEomTag', this.isShowEom);
    },
    checkAll(target) {
      let arr = this.dataMap[target].map(i => i.value);
      // 判断状态：全选与取消全选
      if (this.checkFullMap[target]) { // 此状态已经为全选，故而内部则做取消全选功能，反之亦然
        for(let item of arr) {
          this.tagHandleClose(item)
        }
      } else { // 反选
        for(let item of arr) {
          !this.checkList.includes(item) && this.checkList.push(item);
        }
      }
    },
    checkFull(arr) {
      if (!this.dataMap['Series']) {
        return;
      }
      // 遍历series
      let series = this.dataMap['Series'].map(i => i.value);
      this.checkFullMap['Series'] = this.checkFullHander(arr, series);
      // 遍历form
      if (this.dataMap['Form']) {
        let form   = this.dataMap['Form'].map(i => i.value);
        this.checkFullMap['Form'] = this.checkFullHander(arr, form);
      }
    },
    // 判断是否全部选择了
    checkFullHander(arr, series) {
      let count = 0;
      for (let item of series) {
        if (arr.includes(item)) {
          count += 1;
        }
      }
      if (count === series.length) {
        return true;
      }
      return false;
    },
    filterChange: throttle(function() {
      let ids = [];
      for (let key of this.checkList) {
        ids.push(this.vkMap[key]);
      }
      let queryParam = {
        'filterIds': ids,
        'language': this.$i18n.locale,
        // 'otherParam': this.keyWord,
        'type': this.productType.find(item => item.id === this.typeActiveId).value,
      };
      this.$emit('change', queryParam);
    }, 300, {'trailing': true}),

    tipsBoxOver() {
      this.other = [];
      this.index = '';
      this.unhidden = false;
    },
    hoverOver() {
      this.index = '';
      this.unhidden = false;
    },
    tipsBoxEnter() {
      this.index = this.index2;
      this.unhidden = true;
    },
    hoverEnter(e, item) {
      this.offsetTop = (e.target.offsetTop + e.target.offsetHeight - 1.5) + 'px';
      this.other = item.value;
      this.index = item.type;
      this.index2 = item.type;
      this.unhidden = true;
    },
    async init () {
      await this.getProductType()
    },
    async getProductType() {
      const {data: {isSuccess, data}} = await getProductTypeFilter()
      if (isSuccess) {
        this.productType = data;
        // this.typeActive(data[0])
        this.getAllProduct()
      }
    },
    async getFilterByProType() {
      let type = this.productType.find(item => item.id === this.typeActiveId).value;
      const {data: {isSuccess, data}} = await getFilterItem({type: type})
      if (isSuccess) {
        let obj = {};
        for (let item of data) {
          if (['Series', 'Form'].includes(item.type)) {
            obj[item.type] = item.value;
          } else {
            (obj['other'] || (obj['other'] = [])).push(item);
          }
        }
        this.dataMap = obj;
        this.dimensionalityReduction(data);
      }
    },
    // 降维
    dimensionalityReduction(data) {
      let arr = [];
      for (let item of data) {
        arr = [...arr, ...item.value]
      }
      this.vkMap = {};
      arr.forEach(item => {
        this.vkMap[item.value] = item.id;
      })
    },
    // 点击 product type 下面的 All 按钮后获得所有
    getAllProduct() {
      this.isAll = !this.isAll
      if (this.isAll) {
        this.dataMap = {
          other: []
        };
        let queryParam = {
          'language': this.$i18n.locale,
        };
        this.$emit('search', queryParam);
      }
    },
    search: throttle(function() {
      let queryParam = {
        'language': this.$i18n.locale,
        'otherParam': this.keyWord,
      };
      if (!this.isAll) {
        queryParam['type'] = this.productType.find(item => item.id === this.typeActiveId).value
      }
      this.$emit('search', queryParam);
    }, 300, {'trailing': true}),
    typeActive(item) {
      this.keyWord = '';
      this.isAll = false;
      this.typeActiveId = item.id;
      this.getFilterByProType();
      this.clearAllChecked();
      // this.filterChange()
    },
    tagHandleClose(item) {
      let index = this.checkList.indexOf(item);
      this.checkList.splice(index, 1);
    },
    clearAllChecked() {
      this.checkList = [];
    },
  }
}
</script>

<style lang="less" scoped>
.t-clear {
  // background-color: #f9e6e7;
  border-color: #f4ccce;
  color: #c7000b;
  &:hover {
    background-color: #f9e6e7;
  }
}
.expand {
  display: flex;
  justify-content: center;
  padding: 8px 0;
  cursor: pointer;
  align-items: center;
  gap: 4px;
  background-color: rgba(241, 244, 246, .4);
  margin: 0 -16px;
}
.filter-area-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
  flex-wrap: wrap;
  .right-wrap {
    display: flex;
    align-items: center;
    max-width: 100%;
    flex: 1;
    justify-content: flex-end;
    .total-num {
      padding: 10px 20px 10px 0;
      display: flex;
      align-items: center;
      position: relative;
      gap: 4px;
      white-space: nowrap;
    }
    /deep/.el-input {
      width: 360px;
    }
  }
}
.tag-group {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;

  .tag-group-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex: 1;
    padding-right: 10px;
  }
}
.filter-area {
  margin: 20px 0 20px;
  background: #fff;
  font-size: 14px;
  .filter-area-content {
    border: 1px solid #eaeef1;
    padding: 0 15px;
  }
}
.filter {
  display: flex;
  align-items: baseline;
  padding: 10px 0;
  border-bottom: 1px solid #eaeef1;
  position: relative;

  .filter-label {
    color: #626a73;
    font-weight: 400;
    min-width: 150px;
  }
  .filter-content {
    margin-left: 20px;
    .filter-content-all {
      font-size: 14px; 
      padding: 5px 10px; 
      cursor: pointer; 
      margin: 0 30px 0 3px;
      border: 1px solid transparent;
    }
    .filter-content-all_active {
      border: 1px solid var(--themeColor);
      color: var(--themeColor);
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 14px;
        padding: 5px 10px;
        border: 1px solid transparent;
        cursor: pointer;
        margin: 0 3px;
        position: relative;
      }
      .active {
          border: 1px solid var(--themeColor);
          color: var(--themeColor);
      }
    }
    .tags {
      padding: 0;
      border: 1px solid transparent;
      .tag {
        padding: 5px 10px;
      }
    }
    .tags_hover {
      position: relative;
      z-index: 2;
      border: 1px solid var(--themeColor);
      border-bottom: transparent;
      background-color: #ffffff;
      .tag {
        color: var(--themeColor);
      }
    }
    .tips-box {
      background-color: #ffffff;
      position: absolute;
      left: -1px;
      border: 1px solid var(--themeColor);
      padding: 10px;
      width: 100%;
      z-index: 1;
      /deep/.el-checkbox-group {
        width: 100%;
      }
    }
  }
  
}
@media screen and (max-width: 991px) {
  .filter {
    display: block;
    padding-bottom: 10px;
  }
  .filter-content {
    .tips-box {
      ::v-deep .el-checkbox-group{
        .el-checkbox {
          .el-checkbox__label {
            white-space: normal;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .filter-area-search {
    .right-wrap {
      display: block;
      ::v-deep .el-input {
        width: 100%;
      }
    }
  }
}
</style>